<template>
  <div class="page-mycommunity dash-community">
    <language-selector />
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 mb-5">
          <div class="pd-highlight">
            <h1 class="text-center">
              {{ $t("dash.community.notamember.titulo") }}
            </h1>
          </div>
        </div>
        <div
          class="col-lg-4 card card-prices border-terciary mb-3 margin-mobile"
        >
          <div class="card-header text-center">open</div>
          <div class="card-body text-primary text-center">
            <div class="wrapper-tips mb-4">
              <h5 class="card-title d-inline-block mb-0">
                {{ $t("dash.community.notamember.txt_1") }}
              </h5>
            </div>
            <ul class="pb-4">
              <li class="card-text">
                {{ $t("dash.community.notamember.txt_2") }}
                <div class="btn-question d-inline-block m-0">
                  <span
                    class="btn btn-icons terciary m-0"
                    data-toggle="tooltip"
                    data-placement="right"
                    :title="$t('dash.community.notamember.txt_2_tip')"
                    >?</span
                  >
                </div>
              </li>

              <li class="card-text">
                <div
                  class="d-inline"
                  v-html="$t('dash.community.notamember.txt_novo_1')"
                ></div>
                <div class="btn-question d-inline-block m-0">
                  <span
                    class="btn btn-icons terciary m-0"
                    data-toggle="tooltip"
                    data-placement="right"
                    :title="$t('dash.community.notamember.txt_novo_1_tip')"
                    >?</span
                  >
                </div>
              </li>

              <li class="card-text">
                {{ $t("dash.community.notamember.txt_novo_2") }}
                <!-- <div class="btn-question d-inline-block m-0">
                  <span class="btn btn-icons terciary m-0" data-toggle="tooltip" data-placement="right" :title='$t("dash.community.notamember.txt_2_tip")'>?</span>
                </div> -->
              </li>

              <li class="card-text">
                {{ $t("dash.community.notamember.txt_4") }}
                <div class="btn-question d-inline-block m-0">
                  <span
                    class="btn btn-icons terciary m-0"
                    data-toggle="tooltip"
                    data-placement="right"
                    :title="$t('dash.community.notamember.txt_4_tip')"
                    >?</span
                  >
                </div>
              </li>
            </ul>
            <router-link
              to="/dashboard/client/new-briefing"
              class="btn btn-outline-primary sm w-fit-content mt-2"
              >{{ $t("dash.iniciar_b") }}
            </router-link>
          </div>
        </div>

        <div
          class="col-lg-4 card card-prices border-primary mb-3 margin-top-negative margin-mobile"
        >
          <div class="card-header text-center">Community</div>
          <div class="card-body text-primary text-center">
            <div class="wrapper-tips">
              <h5 class="card-title d-inline-block">
                {{ $t("dash.community.notamember.txt_5") }}
              </h5>
            </div>
            <p class="black underline mb-5">
              {{ $t("dash.community.notamember.txt_5_2") }}
            </p>
            <ul>
              <!-- <li class="card-text">{{ $t("dash.community.notamember.txt_6") }}
                <div class="btn-question d-inline-block m-0">
                  <span class="btn btn-icons terciary m-0" data-toggle="tooltip" data-placement="right" :title='$t("dash.community.notamember.txt_6_tip")'>?</span>
                </div>
              </li> -->
              <li class="card-text">
                {{ $t("dash.community.notamember.txt_7") }}
                <div class="btn-question d-inline-block m-0">
                  <span
                    class="btn btn-icons terciary m-0"
                    data-toggle="tooltip"
                    data-placement="right"
                    :title="$t('dash.community.notamember.txt_7_tip')"
                    >?</span
                  >
                </div>
              </li>
              <li class="card-text">
                Creators Wishlist
                <div class="btn-question d-inline-block m-0">
                  <span
                    class="btn btn-icons terciary m-0"
                    data-toggle="tooltip"
                    data-placement="right"
                    :title="$t('dash.community.notamember.txt_8_tip')"
                    >?</span
                  >
                </div>
              </li>
              <li class="card-text">
                {{ $t("dash.community.notamember.txt_10") }}
                <div class="btn-question d-inline-block m-0">
                  <span
                    class="btn btn-icons terciary m-0"
                    data-toggle="tooltip"
                    data-placement="right"
                    :title="$t('dash.community.notamember.txt_9_tip')"
                    >?</span
                  >
                </div>
              </li>
            </ul>
            <a
              href="mailto:hello@creators.llc"
              class="btn btn-primary sm w-fit-content"
              >{{ $t("dash.community.notamember.assinar") }}</a
            >
          </div>
        </div>

        <div
          class="col-lg-4 card card-prices border-terciary mb-3 margin-mobile"
        >
          <div class="card-header text-center">business</div>
          <div class="card-body text-primary text-center">
            <div class="wrapper-tips mb-2 pb-1">
              <h5 class="card-title d-inline-block">
                {{ $t("dash.community.notamember.txt_11") }}
              </h5>
            </div>
            <ul>
              <li class="card-text">
                {{ $t("dash.community.notamember.txt_6") }}
                <div class="btn-question d-inline-block m-0">
                  <span
                    class="btn btn-icons terciary m-0"
                    data-toggle="tooltip"
                    data-placement="right"
                    :title="$t('dash.community.notamember.txt_6_tip')"
                    >?</span
                  >
                </div>
              </li>
              <li class="card-text">
                {{ $t("dash.community.notamember.txt_12") }}
                <div class="btn-question d-inline-block m-0">
                  <span
                    class="btn btn-icons terciary m-0"
                    data-toggle="tooltip"
                    data-placement="right"
                    :title="$t('dash.community.notamember.txt_12_tip')"
                    >?</span
                  >
                </div>
              </li>
              <li class="card-text">
                creators wishlist
                <div class="btn-question d-inline-block m-0">
                  <span
                    class="btn btn-icons terciary m-0"
                    data-toggle="tooltip"
                    data-placement="right"
                    :title="$t('dash.community.notamember.txt_8_tip')"
                    >?</span
                  >
                </div>
              </li>
              <li class="card-text">
                {{ $t("dash.community.notamember.txt_13") }}
                <!-- <div class="btn-question d-inline-block m-0">
                  <span class="btn btn-icons terciary m-0" data-toggle="tooltip" data-placement="right" :title='$t("dash.community.notamember.txt_4_tip")'>?</span>
                </div> -->
              </li>
            </ul>
            <a
              href="mailto:rdg@creators.llc"
              class="btn btn-outline-primary sm w-fit-content"
              >{{ $t("dash.community.notamember.assinar") }}</a
            >
          </div>
        </div>

        <!-- <div class="col-12 mt-5">
        <p class="black">* Taxas de curadoria, intermediação, processamento e administração de pagamento de 25%</p>
        <p class="black">** Taxas de curadoria, intermediação, processamento e administração de pagamento de 15%</p>
        <p class="black">*** Taxas de curadoria, intermediação, processamento e administração de pagamento regressiva </p>
      </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //
    };
  },
  mounted() {
    $('[data-toggle="tooltip"]').tooltip();
  },
};
</script>
